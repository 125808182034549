.tw-title{
  @apply md:text-5xl text-3xl font-bold text-center uppercase text-secondary cursor-pointer hover:after:w-[100%] after:transition-all after:duration-500 relative after:absolute after:-bottom-2 after:left-0 after:w-[20%] after:rounded after:bg-primary after:h-1  w-max mx-auto ;
}
.tw-after-title{
  @apply  mx-2 uppercase cursor-pointer hover:after:w-[100%] after:transition-all after:duration-500 relative after:absolute after:-bottom-2 after:left-0 after:w-[0%] after:rounded after:bg-primary after:h-1  w-max ;
}

.tw-header{
  @apply text-3xl font-bold
}

.tw-body{
  @apply text-xl text-gray-700 font-semibold
}