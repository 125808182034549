@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
  font-family: 'Almarai', sans-serif;
}

html {
  overflow-x: hidden;

}

body {
  font-family: 'Almarai', sans-serif;
  direction: rtl;
  overflow: hidden;
  background-color: #FFF;
  /* background-image: url("/public/media/plus.svg"); */
  
}



.clipped {
  background-image: url('/public/assets/home/section6/v1.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.active{
  @apply text-primary;
}


/* Hide the increment and decrement buttons */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.arrow_btn:hover svg {
  @apply -translate-x-2 transition-all;  
} 


::-webkit-scrollbar{
  @apply w-2 h-2 ;
}
::-webkit-scrollbar-thumb {
  @apply bg-primary backdrop-blur rounded;
}
::-webkit-scrollbar-track {
  @apply blur bg-gray-300 rounded;
}